<template>
  <div
    class="error__page d-flex flex-column align-items-center justify-content-center"
  >
    <img
      class="error__page__img"
      src="@/assets/images/error-404.svg"
      alt="Error"
    />
    <h2 class="my-2 error__page__text-404">404</h2>
    <h3 class="error__page__text w-50 text-center">
      A broken robot with a message "Looks like our robots are on strike, the
      page you're looking for couldn't be found."
    </h3>
    <div
      @click.stop="$router.push(`/${orgId}`)"
      class="error__page__back-btn cursor-pointer mt-3"
    >
      Back to home
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    orgId() {
      return this.$store.state.user.orgId;
    },
  },
};
</script>

<style lang="scss" scoped>
.error__page {
  height: 100vh;
  background-color: #ffffff;
  &__back-btn {
    padding: 0.5em 1em;
    background: var(--app-theme-color);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 5px;
    color: #fff;
    font-size: 1.25em;
    font-weight: bold;
  }
  &__text {
    color: #6b7285;
    font-weight: 400;
    &-404 {
      color: #1e263c;
      font-weight: 700;
      font-size: 2.5em;
    }
  }
}

@media only screen and (max-width: 600px) {
  .error__page {
    &__img {
      width: 60%;
    }
    &__back-btn {
      font-size: 1em;
    }
    &__text {
      font-size: 1.25em;
    }
  }
}
</style>
